/**!
 * Plugin Name: WP Featherlight
 * Version:     1.3.4
 * Author:      Cipher
 * License:     GPL-2.0+
 */
/* Base Styles
--------------------------------------------- */
.featherlight {
  background: transparent;
  bottom: 0;
  cursor: zoom-out;
  display: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  white-space: nowrap;
  z-index: 2147483647;
}
.featherlight * {
  box-sizing: border-box;
}
.featherlight:last-of-type {
  background: rgba(0, 0, 0, 0.9);
}
.featherlight::before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: -0.25em;
  vertical-align: middle;
}
.featherlight .featherlight-content {
  animation: fadein 0.5s;
  background: #000;
  border: 0;
  cursor: auto;
  display: inline-block;
  max-height: 80%;
  max-width: 90%;
  min-width: inherit;
  overflow: visible;
  padding: 0;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
}
@media screen and (min-width: 980px) {
  .featherlight .featherlight-content {
    max-height: 90%;
  }
}
.featherlight .featherlight-content .caption {
  color: #fff;
  font-size: 16px;
  font-weight: lighter;
  line-height: 1.25;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.featherlight .featherlight-content .caption:hover, .featherlight .featherlight-content .caption:focus {
  overflow: visible;
  white-space: normal;
}
.featherlight .featherlight-content a {
  color: #fff;
  text-decoration: underline;
}
.featherlight .featherlight-content a:hover, .featherlight .featherlight-content a:focus {
  text-decoration: none;
}
.featherlight .featherlight-inner {
  animation: fadein 0.5s;
  display: block;
}
.featherlight button {
  -webkit-appearance: button;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
}
.featherlight button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.featherlight .featherlight-close-icon {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%09%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M21%204.41L19.59%203%2012%2010.59%204.41%203%203%204.41%2010.59%2012%203%2019.59%204.41%2021%2012%2013.41%2019.59%2021%2021%2019.59%2013.41%2012%2021%204.41z%22/%3E%0A%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border: 0;
  cursor: pointer;
  display: block;
  height: 30px;
  opacity: 0.6;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 25px;
  text-align: center;
  text-indent: 100%;
  top: 25px;
  white-space: nowrap;
  width: 30px;
  z-index: 9999;
}
.featherlight .featherlight-close-icon:hover, .featherlight .featherlight-close-icon:focus {
  opacity: 1;
}
.featherlight .featherlight-image {
  max-width: 100%;
}
.featherlight iframe {
  border: 0;
}

[data-featherlight] img {
  cursor: zoom-in;
}

.featherlight-iframe .featherlight-content {
  border-bottom: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: 0;
}

/* Animated Loader
--------------------------------------------- */
@keyframes featherlightLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.featherlight-loading .featherlight-content {
  animation: featherlightLoader 1s infinite linear;
  background: transparent;
  border: 8px solid #909090;
  border-left-color: #fff;
  font-size: 10px;
}
.featherlight-loading .featherlight-content,
.featherlight-loading .featherlight-content::after {
  border-radius: 50%;
  height: 10em;
  width: 10em;
}
.featherlight-loading .featherlight-close,
.featherlight-loading .featherlight-inner {
  display: none;
}

/* Gallery
--------------------------------------------- */
.featherlight-next,
.featherlight-previous {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;
  display: block;
  height: 60px;
  margin-top: -30px;
  opacity: 0.4;
  overflow: hidden;
  position: fixed;
  text-indent: 100%;
  top: 50%;
  user-select: none;
  white-space: nowrap;
  width: 60px;
}
.featherlight-next span,
.featherlight-previous span {
  display: none;
}
.featherlight-next:hover, .featherlight-next:focus,
.featherlight-previous:hover,
.featherlight-previous:focus {
  opacity: 1;
}

/* rtl:ignore */
.featherlight-next {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%09%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M8.59%2016.59L13.17%2012%208.59%207.41%2010%206l6%206-6%206-1.41-1.41z%22/%3E%0A%3C/svg%3E");
  background-position: 0 0;
  right: 10px;
}

/* rtl:ignore */
.featherlight-previous {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%09%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M15.41%207.41L10.83%2012l4.58%204.59L14%2018l-6-6%206-6%201.41%201.41z%22/%3E%0A%3C/svg%3E");
  background-position: -5px 0;
  left: 10px;
}

.featherlight-loading .featherlight-previous,
.featherlight-loading .featherlight-next {
  display: none;
}